
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
const addressData = require('@/plugins/provinces.json')

@Options({
  components: {}
})
export default class EditPersonMsg extends SuperVue {
  created() {
    this.getDeptList()
    this.getCategoryList()

  }
  show() {
    this.dialogData.show = true
    this.getUser()
    console.log(this.$store.state.curRoleId)
    if (this.$store.state.curRoleId === 3) {
      this.dialogData.showExpertInfo = true
    } else {
      this.dialogData.showExpertInfo = false
    }
  }
  hide() {
    this.dialogData.show = false
  }
  // 获取用户信息
  getUser() {
    Api.http_personal_center_getUser({}).then((res) => {
      if (res.code === '200') {
        console.log('aaaa', res.data);
        this.editData.first_name = res.data.first_name
        this.editData.last_name = res.data.last_name
        this.editData.sex = res.data.sex
        this.editData.phone = res.data.phone
        this.editData.wechat = res.data.wechat
        this.editData.email = res.data.email
        this.editData.birthday = res.data.birthday
        this.editData.post_status = res.data.post_status
        this.editData.culture = res.data.culture
        this.editData.post = res.data.post
        this.editData.post_rank = res.data.post_rank
        this.editData.age_limit = res.data.age_limit
        this.editData.nature = res.data.nature
        this.editData.duty = res.data.duty
        this.editData.social_position = res.data.social_position
        if (Utils.isJSON(res.data.area)) this.editData.area = JSON.parse(res.data.area)
        this.editData.self_evaluation = res.data.self_evaluation
        this.editData.is_patent = res.data.is_patent
        this.editData.patent_code = res.data.patent_code
        if (Utils.isJSON(res.data.patent_file)) this.editData.patent_file = JSON.parse(res.data.patent_file)
        this.editData.is_market = res.data.is_market
        this.editData.market_code = res.data.market_code
        if (Utils.isJSON(res.data.patent_file)) this.editData.market_file = JSON.parse(res.data.market_file)
        this.editData.industry_category_ids = res.data.industry_category_id
        // const temp = res.data.related_industry_ids.split(',')
        // console.log(temp)
        this.editData.related_industry_ids = res.data.related_industry_ids
        // console.log(this.categoryList)
        this.editData.work_unit = res.data.work_unit
        this.editData.profession = res.data.profession
        this.editData.domain = res.data.domain

      }
    })
  }
  dialogData = {
    show: false,
    showExpertInfo: false // 展示专家特有信息
  }
  editData={
    first_name: '',
    last_name: '',
    sex: 1,
    phone: '',
    wechat: '',
    email: '',
    birthday: '',
    post_status: null,
    culture: null,
    post: '',
    post_rank: null,
    age_limit: '',
    nature: null,
    duty: '',
    social_position: '',
    area: [],
    self_evaluation: '',
    is_patent: 0,
    patent_code: '',
    patent_file: [],
    is_market: 0,
    market_code: '',
    market_file: [],
    industry_category_ids: [] as any[],
    related_industry_ids: [],
    work_unit: '',
    profession: [],
    domain: ''
  }
  editRule = {
    item1: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
    item2: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
    item5: [{ required: true, message: '职称级别不能为空', trigger: 'blur' }],
    item7: [{ required: true, type: 'array', message: '所属行业不能为空', trigger: 'blur' }],
    item14: [{ required: true, message: '出生年月不能为空', trigger: 'blur' }],
    item30: [{ required: true, message: '身份证不能为空', trigger: 'blur' }, {
      pattern:
      /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '身份证格式不正确', trigger: 'blur'
    }]
  }
  workUtilList=[]
  cultureOption= [
    {
      value: 1,
      label: '博士'
    },
    {
      value: 2,
      label: '硕士'
    },
    {
      value: 3,
      label: '本科'
    },
    {
      value: 4,
      label: '大专'
    },
    {
      value: 5,
      label: '大专以下'
    }
  ]
  postOption = [
    { value: '无', label: '无' },
    { value: '初级', label: '初级' },
    { value: '中级', label: '中级' },
    { value: '副高级', label: '副高级' },
    { value: '正高级', label: '正高级' }
  ]
  postStatusOption=[
    { label: '在职', value: 1 },
    { label: '离职', value: 2 }
  ]
  unitOption = [
    { label: '政府单位', value: 0 },
    { label: '高校', value: 1 },
    { label: '科研机构', value: 2 },
    { label: '科技服务机构', value: 3 },
    { label: '医院', value: 4 },
    { label: '社会团体', value: 5 },
    { label: '其他事业单位', value: 6 },
    { label: '军队武警', value: 7 },
    { label: '国企', value: 8 },
    { label: '民企', value: 9 },
    { label: '外企', value: 10 },
    { label: '混合所有制企业', value: 11 },
    { label: '境外机构', value: 12 },
    { label: '其他组织', value: 13 }
  ]
  depts = []
  // 获取部门列表
  getDeptList() {
    Api.http_queryDeptsList({ offset: 0, length: 999 }).then(res => {
      this.depts = Utils.list2tree(res.data.data, 'id', 'parent_id', 'children')
    })
  }
  categoryList=[]
  selectList=[]
  // 获取行业分类
  getCategoryList() {
    const postData = {
      offset: 0,
      length: 9999,
      type: 1
    }
    Api.http_getCategoryList(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message); // 接口出错
      } else {
        const data = res.data.data
        // data.forEach(item => { item.label = item.name; item.value = item.name })
        // const tree = Utils.list2tree(data, 'id', 'parent_id', 'children')
        this.categoryList = data
        this.selectList = data
      }
    });
  }
  // 获取工作单位
  getWorkIndustryList() {
    const postData = {
      offset: 0,
      length: 9999,
      type: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    }
    Api.http_industryTableA0(postData).then(res => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.workUtilList = res.data.data
      }
    })
  }
  addressOption = addressData.data
  editConfirm() {
    const postData = JSON.parse(JSON.stringify(this.editData))
    postData.id = this.$store.state.userInfo.id
    // postData.area = JSON.stringify(postData.area)
    // postData.patent_file = JSON.stringify(postData.patent_file)
    // postData.patent_file = JSON.stringify(postData.patent_file)
    Api.http_personal_center_edit(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message)
        this.$emit('needUpdate')
        this.hide()
      }
    })
  }
}
