
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import Config from '@/config'
import store from '@/store'
import EditPersonMsg from './components/EditPersonMsg.vue'

@Options({
  components: {
    PagePlugin,
    EditPersonMsg
  }
})
export default class personal_center extends SuperVue {
  // 上传图片
  // 上传路径
  get uploadUrl() {
    return process.env.NODE_ENV === 'development'
      ? Config.baseUrl.dev
      : Config.baseUrl.pro
  }
  get token() {
    return store.state.token
  }
  uploadSuccess(response) {
    if (response.code === '200') {
      Api.http_personal_center_editUser({ avatar: response.data }).then(
        (res) => {
          if (res.code === '200') this.getUser()
        }
      )
    }
  }
  menuActive = 0
  user: any = {}
  getUser() {
    Api.http_personal_center_getUser({}).then((res) => {
      if (res.code === '200') {
        this.user = res.data
        console.log('aaaa', res);
        if (Utils.isJSON(this.user.patent_file)) { this.user.patent_file = JSON.parse(this.user.patent_file) }
        if (Utils.isJSON(this.user.market_file)) { this.user.market_file = JSON.parse(this.user.market_file) }
        this.user.birthday = Utils.getIdCardInfo(this.user.id_number, 1)
        this.user.roles = this.user.roles.split(',')
      }
    })
  }
  input='aaaaaaaaaaa'
  labelPosition='left'
  //
  registration_data={
    pwdType: 'password',
    // eslint-disable-next-line global-require
    src: require('../../../assets/icon/colse_eyes.png')
  }
  changeType() {
    this.registration_data.pwdType = this.registration_data.pwdType === 'password' ? 'text' : 'password';
    // eslint-disable-next-line global-require
    this.registration_data.src = this.registration_data.src === require('../../../assets/icon/colse_eyes.png') ? require('../../../assets/icon/open_eyes.png') : require('../../../assets/icon/colse_eyes.png');
  }

  bankDialog = {
    open: false,
    id: 0,
    name: '',
    number: '',
    office: '',
    is_default: 0
  }
  bankDialogRules = {
    number: {
      pattern: /^[1-9]\d{12,18}$/,
      message: '银行卡号输入错误',
      trigger: 'change'
    },
    name: { required: true, message: '请填写完整', trigger: 'blur' },
    office: { required: true, message: '请填写完整', trigger: 'blur' }
  }
  banks: any[] = []
  openAddBankDialog() {
    this.bankDialog = {
      open: true,
      id: 0,
      name: '',
      number: '',
      office: '',
      is_default: 0
    }
  }
  openEditBankDialog(item: any) {
    this.bankDialog = {
      open: true,
      id: item.id,
      name: item.name,
      number: item.number,
      office: item.office,
      is_default: item.is_default
    }
  }
  bankFormat(num: string) {
    const arr: string[] = []
    for (let i = 0; i < num.length; i = i + 4) {
      arr.push(num.slice(i, i + 4))
    }
    arr[arr.length - 2] = '****'
    arr[arr.length - 3] = '****'
    return arr.reduce((v, cur) => {
      v += cur + ' '
      return v
    }, '')
  }

  addBank() {
    if (
      !(
        this.bankDialog.name.trim()
        && this.bankDialog.number.trim()
        && this.bankDialog.office.trim()
      )
    ) { return }
    this.$refs['bankDialogForm'].validate((valid) => {
      if (valid) {
        if (this.bankDialog.id === 0) {
          Api.http_personal_center_addBank({
            name: this.bankDialog.name.trim(),
            number: this.bankDialog.number.trim(),
            office: this.bankDialog.office.trim(),
            is_default: this.bankDialog.is_default
          }).then((res) => {
            if (res.code === '200') this.getBankList()
          })
        } else {
          Api.http_personal_center_editBank({
            id: this.bankDialog.id,
            name: this.bankDialog.name.trim(),
            number: this.bankDialog.number.trim(),
            office: this.bankDialog.office.trim(),
            is_default: this.bankDialog.is_default
          }).then((res) => {
            if (res.code === '200') this.getBankList()
          })
        }
        this.bankDialog.open = false
      }
    })
  }
  delBank(id: number) {
    Api.http_personal_center_delBank({ id: id }).then(() => {
      this.getBankList()
    })
  }

  getBankList() {
    Api.http_personal_center_getBankList({}).then((res) => {
      if (res.code === '200') {
        this.banks = res.data
        this.banks.forEach((item) => {
          switch (item.name) {
            case '中国银行':
              item.img = this.$url + '/upload/static/yh1.png'
              break
            case '工商银行':
              item.img = this.$url + '/upload/static/yh3.png'
              break
            case '农业银行':
              item.img = this.$url + '/upload/static/yh2.png'
              break
            case '中国建设银行':
              item.img = this.$url + '/upload/static/yh4.png'
              break
            default:
              item.img = this.$url + '/upload/static/yh5.png'
          }
        })
      }
    })
  }
  pass = {
    showPassBox: false,
    // showPoneBox:false,
    // showEmailBox:false,
    first: '',
    second: ''
  }

  passFormrules = {
    first: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    second: [{ required: true, message: '请输入确认密码', trigger: 'blur' }]
  }
  changePass() {
    this.$refs['passFormRef'].validate((valid) => {
      if (valid) {
        if (this.pass.first.trim() === this.pass.second.trim()) {
          if (!Utils.checkPass(this.pass.first)) {
            this.$message.warning('密码必须由字母、数字、符号组成, 8-12位')
            return
          }
          Api.http_personal_center_editUser({
            password: this.pass.first.trim()
          }).then((res) => {
            if (res.code === '200') {
              console.log('aaaa', res);
              this.$message.success('密码修改成功!')
              this.pass.showPassBox = false
            }
          })
        } else {
          this.$message.warning('前后密码不一致!')
        }
      }
    })
  }
  //
  phone={
    showPhoneBox: false,
    first: '',
    second: ''
  }
  eamil={
    showEamilBox: false
  }
  changeSex(sex: number): void {
    this.user.sex = sex
    Api.http_personal_center_editUser({ sex: sex })
  }
  created() {
    this.getUser()
    this.getBankList()
  }
  needUpdate() {
    this.getUser()
    this.getBankList()
  }
}
